<template>
  <div>
    <v-card>
      <v-card-title> セット（編集） </v-card-title>
      <template>
        <v-spacer></v-spacer>
        <template v-if="apierror.status == 'error'">
          <div v-for="msg in apierror.messages" :key="msg">
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <template>
          <v-divider></v-divider>
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <span>セットコード</span><span style="color:red">*</span>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="selectedSet.set_code"
                readonly
                placeholder="セットコード"
                color="#fa7a00"
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <span>セット名</span><span style="color:red">*</span>
            </v-col>
            <v-col
              cols="12"
              md="7"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="$v.editForm.set_name.$model"
                :error-messages="setNameErrors"
                :counter="100"
                placeholder="セット名"
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <label style="font-size:16px;color:#636363">販売可否</label><span style="color: red">*</span>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="$v.editForm.saleable.$model"
                :error-messages="saleableErrors"
                :items="sellStatus"
                placeholder="販売可否"
                color="#fa7a00"
                hide-details="auto"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <!-- <v-row align="center" class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <label style="font-size:16px;color:#636363">販売価格(税抜き)</label><span style="color: red">*</span>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="py-0 my-0"
            >
              <NumberInput
                v-model="$v.editForm.set_tep.$model"
                :error-messages="setTepErrors"
                :outlined="true"
                prefix="¥"
              ></NumberInput>
            </v-col>
          </v-row> -->
          <!-- <v-row align="center" class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <label style="font-size:16px;color:#636363">消費税</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="$v.editForm.set_ctr.$model"
                :error-messages="setCtrErrors"
                color="#fa7a00"
                :items="taxs"
                :value="taxs"
                suffix="%"
                outlined
                dense
                placeholder="消費税"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="py-0 my-0"
            >
            </v-col>
            <p class="pb-0 mb-0" style="color:#fa7a00;font-size: small;">
              {{ refValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
            </p>
            <p class="pb-0 mb-0 mr-3" style="font-size: normal;">
              (入力値税込価格)
            </p>
            <p class="pb-0 mb-0" style="color:#fa7a00;font-size: small;">
              {{ taxValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
            </p>
            <p class="pb-0 mb-0" style="font-size: normal;">
              (税金)
            </p>
          </v-row> -->
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <label style="font-size:16px;color:#636363">販売価格(税込み)</label><span style="color: red">*</span>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="py-0 my-0"
            >
              <NumberInput
                v-model="$v.editForm.set_tip.$model"
                :error-messages="setTipErrors"
                :outlined="true"
                prefix="¥"
              ></NumberInput>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="py-0 my-0"
            >
            </v-col>
            <!-- <p v-if="gapValueOn" class="pb-0 mb-0" style="color:#FF0000;font-size: small;">
              {{ gapValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
            </p>
            <p v-if="gapValueOn" class="pb-0 mb-0" style="font-size: normal;">
              (計算差額)
            </p> -->
          </v-row>
          <!-- <v-row class="px-2 ma-0 my-2">
            <v-col cols="12" md="2">
              <span style="font-size:16px;color:#636363">内容量</span>
            </v-col>
            <v-col cols="12" md="3" class="py-0 my-0">
              <NumberInput
                v-model="editForm.set_weight"
                :outlined="true"
                :error-messages="setWeightErrors"
                suffix="g"
              ></NumberInput>
            </v-col>
          </v-row> -->
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <label style="font-size:16px;color:#636363">販売開始日</label><span style="color: red">*</span>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="py-0 my-0"
            >
              <v-menu
                v-model="dateStartMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="$v.editForm.set_ssd.$model"
                    :error-messages="setSsdErrors"
                    placeholder="販売開始日"
                    color="#fa7a00"
                    hide-details="auto"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editForm.set_ssd"
                  color="#fa7a00"
                  @input="dateStartMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <label style="font-size:16px;color:#636363">販売終了日</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="py-0 my-0"
            >
              <v-menu
                v-model="dateEndMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editForm.set_sed"
                    :error-messages="setSedErrors"
                    placeholder="販売終了日"
                    color="#fa7a00"
                    dense
                    hide-details="auto"
                    outlined
                    v-bind="attrs"
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editForm.set_sed"
                  color="#fa7a00"
                  :allowed-dates="allowedDates"
                  @input="dateEndMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- <v-row align="center" class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <label style="font-size:16px;color:#636363">販売終了</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="editForm.set_eos"
                :items="sellOver"
                placeholder="販売終了"
                color="#fa7a00"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row> -->
          <v-row align="center" class="px-2 ma-0 mb-2">
            <v-col
              cols="12"
              md="2"
            >
              <label style="font-size:16px;color:#636363">間もなく発売</label><span style="color: red">*</span>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0 my-0"
            >
              <v-select
                v-model="editForm.set_cs_show"
                :items="commingSoon"
                placeholder="間もなく発売"
                color="#fa7a00"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <label style="font-size:16px;color:#636363">荷作り</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0 my-0"
            >
              <NumberInput
                v-model="$v.editForm.s60_max_qty.$model"
                :error-messages="s60MaxQtyErrors"
                :label="`60サイズ`"
                :outlined="true"
              ></NumberInput>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0 my-0"
            >
              <NumberInput
                v-model="editForm.s80_max_qty"
                :error-messages="s80MaxQtyErrors"
                :label="`80サイズ`"
                :outlined="true"
              ></NumberInput>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0 my-0"
            >
              <NumberInput
                v-model="editForm.s100_max_qty"
                :error-messages="s100MaxQtyErrors"
                :label="`100サイズ`"
                :outlined="true"
              ></NumberInput>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row align="center" class="px-2 ma-0 my-1">
            <v-col
              cols="12"
              md="2"
            >
              <span>セット中身</span>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <v-sheet
                width="100%"
                min-height="240"
                outlined
                class="d-flex align-start flex-column"
              >
                <div class="d-flex justify-end px-4 py-1" style="width:100%;">
                  <ProList
                    class="ml-auto"
                    :old-list="editForm.details"
                    :error-messages="setDetailsErrors"
                    :show-btn="true"
                    @add_mainAllList="getaddMainAllList"
                  ></ProList>
                </div>
                <v-divider style="width:100%;"></v-divider>
                <v-list
                  style="width:100%;flex:1;"
                >
                  <draggable
                    v-model="editForm.details"
                    style="height:100%"
                    :group="groupSet"
                    animation="300"
                    @end="onEnd"
                  >
                    <v-list-item
                      v-for="(item, i) in editForm.details"
                      :key="i"
                      style="height:14px; min-height:0;"
                      class="my-3"
                    >
                      <v-list-item-action class="my-0 mr-0 py-0">
                        <v-chip
                          style="font-size:1px;height:20px; min-height:0;width: 100%;"
                          :color="getColor(item)"
                          class="d-flex justify-space-between"
                          @click.stop
                        >
                          <v-icon
                            size="16"
                            color="red"
                            class="mx-1"
                            @click="outFrList(item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                          <span
                            style="font-size:1px; color:black;width: 1000px; overflow: hidden; text-overflow:ellipsis;"
                            class="px-5"
                            :title="item.item_name"
                          >
                            {{ item.item_name }}
                          </span>
                          <v-icon
                            size="16"
                            color="black"
                            class="mx-1"
                            @click="decreaseQty(item)"
                          >
                            {{ icons.mdiMinusBoxOutline }}
                          </v-icon>
                          <span style="color:black;width:20px;text-align:center">{{ item.item_qty }}</span>
                          <v-icon
                            size="16"
                            color="black"
                            class="mx-1"
                            @click="increaseQty(item)"
                          >
                            {{ icons.mdiPlusBoxOutline }}
                          </v-icon>
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </draggable>
                </v-list>
                <v-divider v-if="sumQuantity > 0" style="width:100%;"></v-divider>
                <div v-if="sumQuantity > 0" class="d-flex justify-space-between px-4 py-1" style="width:100%;">
                  <span class="mr-8">
                    品数: {{ sumQuantity }}
                  </span>
                </div>
              </v-sheet>
              <!-- <span v-if="detailsErrors" style="color:red;font-size:smaller;text-align:center">
                中身に商品を設定して下さい。
              </span> -->
            </v-col>
          </v-row>
          <v-row class="px-2 ma-0 my-2">
            <v-col
              id="showStyle"
              cols="12"
              md="2"
            >
              <span>表示レイアウト</span>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="$v.editForm.show_style.$model"
                :error-messages="showStyleErrors"
                placeholder="表示レイアウト"
                color="#fa7a00"
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <span v-if="sum_showStyle > 0"> 合計: {{ sum_showStyle }}</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-title style="font-size:16px">
            イメージ
          </v-card-title>
          <v-divider></v-divider>
          <v-row v-if="files.length !== 0" class="ma-0">
            <draggable
              v-model="files"
              class="d-flex flex-row flex-wrap"
              style="height:100%;width:100%"
              animation="300"
            >
              <v-col
                v-for="(item, i) in files"
                :key="i"
                cols="2"
                @contextmenu.prevent="openMenu($event,i)"
              >
                <v-card
                  style="border: 1px solid #CFD8DC"
                  flat
                  tile
                  outlined
                  color="#fafafa"
                  height="100%"
                >
                  <v-img
                    :src="`${editForm.imgUrl_prefix ? editForm.imgUrl_prefix : ''}/${item.replace(/^\//g, '')}`"
                    height="120px"
                    contain
                  >
                  </v-img>
                </v-card>
              </v-col>
            </draggable>
          </v-row>
          <div v-else>
            <div class="d-flex justify-center align-center my-4">
              <v-img
                src="@/assets/images/noimage.jpg"
                max-width="120"
              >
              </v-img>
            </div>
            <div class="d-flex justify-center align-center my-4">
              <span>[ + ] ボタンを使用して、さらにイメージを追加します。</span>
            </div>
          </div>
          <v-card-actions class="py-3">
            <AddMedia
              :show-btn="false"
              @exportFile="insertImgs"
            ></AddMedia>
          </v-card-actions>

          <v-divider></v-divider>
          <v-row no-gutters class="px-2 ma-3 my-4">
            <v-col
              cols="12"
              md="12"
            >
              <span style="font-size:16px;color:black">商品概要</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-center flex-wrap">
                <v-btn
                  color="#4682B4"
                  elevation="0"
                  class="mb-4 me-3 mt-2"
                  @click="onOverviewEditor"
                >
                  <span style="color:white">編集の表示/非表示</span>
                </v-btn>

                <AddMedia
                  :show-btn="true"
                  @exportFile="insertSetOverview"
                ></AddMedia>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <ckeditor
                v-if="showOverviewEditor"
                v-model="editForm.set_overview"
                :editor="editor"
                :config="editorConfig"
              ></ckeditor>
              <v-textarea
                v-else
                v-model="editForm.set_overview"
                :error-messages="setOverviewErrors"
                name="note1"
                hide-details="auto"
                outlined
                :counter="5000"
                dense
              >
              </v-textarea>
            </v-col>
          </v-row>

          <v-row no-gutters class="px-2 ma-3 my-4">
            <v-col
              cols="12"
              md="12"
            >
              <span style="font-size:16px;color:black">商品説明</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-center flex-wrap">
                <v-btn
                  color="#4682B4"
                  elevation="0"
                  class="mb-4 me-3 mt-2"
                  @click="onDescEditor"
                >
                  <span style="color:white">編集の表示/非表示</span>
                </v-btn>

                <AddMedia
                  :show-btn="true"
                  @exportFile="insertSetDesc"
                ></AddMedia>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <ckeditor
                v-if="showDescEditor"
                v-model="editForm.set_desc"
                :editor="editor"
                :config="editorConfig"
              ></ckeditor>
              <v-textarea
                v-else
                v-model="$v.editForm.set_desc.$model"
                :error-messages="setDescErrors"
                name="note2"
                hide-details="auto"
                outlined
                :counter="5000"
                dense
              >
              </v-textarea>
            </v-col>
          </v-row>

          <!-- <v-divider></v-divider>
          <v-row class="px-2 ma-0 my-2">
            <v-col
              cols="12"
              md="2"
            >
              <label style="font-size:16px;color:#636363">メタタグキーワード</label>
            </v-col>
            <v-col
              cols="12"
              md="9"
              class="py-0 my-0"
            >
              <v-textarea
                v-model="$v.editForm.set_meta_words.$model"
                :error-messages="setMetaWordsErrors"
                color="#fa7a00"
                outlined
                dense
                placeholder="北海道"
                rows="5"
                :counter="200"
                hide-details="auto"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider> -->
          <v-card-actions align="center" class="d-flex justify-center">
            <v-btn
              color="primary"
              class="mr-2"
              elevation="0"
              :loading="submitStatus"
              @click="submit(editForm)"
            >
              <span style="color:white">保存</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              elevation="0"
              @click="cancelClick"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </template>
      </template>
    </v-card>

    <media-edit-menu
      :visible="visible"
      :left="left"
      :top="top"
      @openConfirmDelete="deleteFile"
    ></media-edit-menu>
  </div>
</template>

<script>

import Editor from 'ckeditorplugin'

import {
  mdiMinusBoxOutline,
  mdiPlusBoxOutline,
  mdiCloseCircleOutline,
} from '@mdi/js'
import {
  required,
  maxLength,
  numeric,
  minValue,
} from 'vuelidate/lib/validators'
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'
import AddMedia from '@/components/AddMedia.vue'
import ProList from '@/components/ProList.vue'
import MediaEditMenu from '@/views/bimi/setting/Media/MediaEditMenu.vue'
import NumberInput from '@/components/Input/NumberInput.vue'

export default {
  components: {
    AddMedia,
    ProList,
    MediaEditMenu,
    NumberInput,
  },
  data: () => ({
    submitStatus: false,
    visible: false,
    top: 0,
    left: 0,
    rightClickIndex: '',
    editor: Editor,
    editorConfig: {
      // The configuration of the editor.
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    groupSet: {
      name: 'Set',
      pull: false,
      put: false,
    },
    icons: {
      mdiMinusBoxOutline,
      mdiPlusBoxOutline,
      mdiCloseCircleOutline,
    },
    files: [],
    images: [],
    editForm: {
      set_id: '',
      set_name: '',
      set_code: '',
      saleable: '',
      details: [],
      imgUrl_prefix: '',
      set_images: '',
      set_overview: '',
      set_desc: '',
      set_tep: '',
      set_ctr: '',
      set_tip: '',
      set_weight: 0,
      set_ssd: '',
      set_sed: '',
      set_eos: '',
      show_style: '',
      set_meta_words: '',
      s60_max_qty: '',
      s80_max_qty: '',
      s100_max_qty: '',
      set_cs_show: 0,
    },
    taxValue: 0,
    refValue: '',
    gapValue: 0,
    gapValueOn: false,
    setSsdFlag: true,
    foodEdit: false,
    unFoodEdit: false,
    showOverviewEditor: true,
    showDescEditor: true,
    dateEndMenu: false,
    dateStartMenu: false,
    taxs: [8, 10],
    sellStatus: [
      {
        text: '販売不可', value: 0,
      },
      {
        text: '販売可', value: 1,
      },
    ],
    commingSoon: [
      {
        text: '非表示', value: 0,
      },
      {
        text: '表示', value: 1,
      },
    ],

    sellOver: [
      {
        text: 'YES', value: 1,
      },
      {
        text: 'NO', value: 9,
      },
    ],

    sum_showStyle: 0,

    // detailsErrors: false,
  }),
  validations: {
    editForm: {
      set_name: {
        required,
        maxLength: maxLength(100),
      },

      // set_code: {
      //   required,
      //   maxLength: maxLength(13),
      // },
      saleable: {
        required,
      },
      set_images: {
        maxLength: maxLength(1000),
      },
      set_overview: {
        // required,
        maxLength: maxLength(5000),
      },
      set_desc: {
        // required,
        maxLength: maxLength(5000),
      },
      set_tep: {
        // required,
        numeric,

        // minValue: minValue(1),
      },
      set_ctr: {
        // required,
        numeric,
      },
      set_tip: {
        required,
        numeric,
        minValue: minValue(1),
      },
      show_style: {
        numeric,
      },
      set_weight: {
        // required,
        numeric,

        // minValue: minValue(1),
      },
      set_ssd: {
        required,
      },
      set_sed: {
        required,
      },
      set_meta_words: {
        maxLength: maxLength(200),
      },
      s60_max_qty: {
        required,
        numeric,
      },
      s80_max_qty: {
        required,
        numeric,
        minValue: minValue(1),
      },
      s100_max_qty: {
        required,
        numeric,
        minValue: minValue(1),
      },
      details: {
        $each: {
          item_code: {
            required,
          },
          item_name: {
            required,
          },
          item_disp_order: {
            required,
          },
        },
      },
    },
  },

  computed: {
    ...mapState('setStore', ['selectedSet']),
    sumQuantity() {
      return this.editForm.details.length
    },
    s60MaxQtyErrors() {
      const errors = []
      if (!this.$v.editForm.s60_max_qty.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.s60_max_qty.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.s60_max_qty.numeric && errors.push('必須数字')

      return errors
    },
    s80MaxQtyErrors() {
      const errors = []
      if (!this.$v.editForm.s80_max_qty.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.s80_max_qty.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.s80_max_qty.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.s80_max_qty.minValue && errors.push('荷作り(80サイズ)は0以外にしてください')

      return errors
    },
    s100MaxQtyErrors() {
      const errors = []
      if (!this.$v.editForm.s100_max_qty.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.s100_max_qty.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.s100_max_qty.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.s100_max_qty.minValue && errors.push('荷作り(100サイズ)は0以外にしてください')

      return errors
    },
    setNameErrors() {
      const errors = []
      if (!this.$v.editForm.set_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_name.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_name.maxLength && errors.push('100文字以内にしてください。')

      return errors
    },

    // setCodeErrors() {
    //   const errors = []
    //   if (!this.$v.editForm.set_code.$dirty) return errors

    //   // eslint-disable-next-line no-unused-expressions
    //   !this.$v.editForm.set_code.required && errors.push('必須項目')

    //   // eslint-disable-next-line no-unused-expressions
    //   !this.$v.editForm.set_code.maxLength && errors.push('9文字以内にしてください。')

    //   return errors
    // },

    saleableErrors() {
      const errors = []
      if (!this.$v.editForm.saleable.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.saleable.required && errors.push('必須項目')

      return errors
    },

    setImagesErrors() {
      const errors = []
      if (!this.$v.editForm.set_images.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_images.maxLength && errors.push('1000文字以内にしてください。')

      return errors
    },

    setOverviewErrors() {
      const errors = []
      if (!this.$v.editForm.set_overview.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.editForm.set_overview.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_overview.maxLength && errors.push('5000文字以内にしてください。')

      return errors
    },

    setDescErrors() {
      const errors = []
      if (!this.$v.editForm.set_desc.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.editForm.set_desc.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_desc.maxLength && errors.push('5000文字以内にしてください。')

      return errors
    },

    // setTepShowErrors() {
    //   const errors = []
    //   if (!this.$v.editForm.set_tep_show.$dirty) return errors

    //   // eslint-disable-next-line no-unused-expressions
    //   !this.$v.editForm.set_tep_show.required && errors.push('必須項目')

    //   return errors
    // },
    setTepErrors() {
      const errors = []
      if (!this.$v.editForm.set_tep.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.editForm.set_tep.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_tep.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.editForm.set_tep.minValue && errors.push('販売価格(税抜き)は0以外にしてください')

      return errors
    },

    setCtrErrors() {
      const errors = []
      if (!this.$v.editForm.set_ctr.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.editForm.set_ctr.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_ctr.numeric && errors.push('必須数字')

      return errors
    },

    setTipErrors() {
      const errors = []
      if (!this.$v.editForm.set_tip.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_tip.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_tip.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_tip.minValue && errors.push('販売価格(税込み)は0以外にしてください')

      return errors
    },

    showStyleErrors() {
      const errors = []
      if (!this.$v.editForm.show_style.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.show_style.numeric && errors.push('必須数字')

      return errors
    },

    setWeightErrors() {
      const errors = []
      if (!this.$v.editForm.set_weight.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.editForm.set_weight.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_weight.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.editForm.set_weight.minValue && errors.push('内容量は0以外にしてください')

      return errors
    },

    setSsdErrors() {
      const errors = []
      if (!this.$v.editForm.set_ssd.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_ssd.required && errors.push('必須項目')

      return errors
    },

    setSedErrors() {
      const errors = []
      if (!this.$v.editForm.set_sed.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_sed.required && errors.push('販売開始日以後にしてください。')

      return errors
    },

    setMetaWordsErrors() {
      const errors = []
      if (!this.$v.editForm.set_meta_words.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.editForm.set_meta_words.maxLength && errors.push('200文字以内にしてください。')

      return errors
    },

    setDetailsErrors() {
      const errors = []
      if (!this.$v.editForm.details.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.editForm.details.required && errors.push('必須項目')

      return errors
    },
  },

  watch: {
    'editForm.show_style': {
      handler(value) {
        if (value) {
          let sum = 0
          value.split('').forEach(s => {
            sum += parseInt(s, 10)
          })

          this.sum_showStyle = sum
        }
      },
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },

    'editForm.set_ssd': {
      handler(newValue) {
        if (this.setSsdFlag) {
          this.setSsdFlag = false
        } else {
          const sedValue = new Date(Date.parse(newValue))

          // 3ヶ月後の最後の日
          // this.sedNumber = this.sedValue.setMonth(this.sedValue.getMonth() + 4)
          // this.sedNumber = new Date(this.sedNumber).toISOString().substring(0, 10)
          // this.sedNumber = new Date(Date.parse(this.sedNumber))
          // this.sedNumber = this.sedNumber.setDate(0)

          // 3ヶ月後
          const sedMonth = sedValue.setMonth(sedValue.getMonth() + 3)
          const sedDate = new Date(sedMonth).setDate(sedValue.getDate() - 1)

          // 89日後
          // this.sedNumber = this.sedValue.setDate(this.sedValue.getDate() + 89)
          this.editForm.set_sed = new Date(sedDate).toISOString().substring(0, 10)
        }
      },
    },

    // 'editForm.set_tep': {
    //   handler() {
    //     if (this.flag === true) {
    //       this.taxValue = (this.editForm.set_tep * (this.editForm.set_ctr / 100)).toFixed(2)
    //       if (Number(this.editForm.set_tep) !== this.selectedSet.set_tep) {
    //         console.log('true#')
    //         this.editForm.set_tip = Math.round(this.refValue)
    //         this.taxValue = (this.editForm.set_tep * (this.editForm.set_ctr / 100)).toFixed(2)
    //         this.refValue = (this.editForm.set_tep * ((100 + this.editForm.set_ctr) / 100)).toFixed(2)
    //         this.flag = false
    //       }
    //     } else if (this.flag === false) {
    //       this.refValue = (this.editForm.set_tep * ((100 + this.editForm.set_ctr) / 100)).toFixed(2)
    //       this.editForm.set_tip = Math.round(this.refValue)
    //       this.taxValue = (this.editForm.set_tep * (this.editForm.set_ctr / 100)).toFixed(2)
    //       this.gapValue = (this.editForm.set_tip - this.refValue).toFixed(2)
    //     }
    //   },
    // },
    // 'editForm.set_ctr': {
    //   handler() {
    //     if (this.flag === true) {
    //       this.refValue = (this.editForm.set_tep * ((100 + this.editForm.set_ctr) / 100)).toFixed(2)
    //       if (Number(this.editForm.set_ctr) !== this.selectedSet.set_ctr) {
    //         this.refValue = (this.editForm.set_tep * ((100 + this.editForm.set_ctr) / 100)).toFixed(2)
    //         this.taxValue = (this.editForm.set_tep * (this.editForm.set_ctr / 100)).toFixed(2)
    //         this.gapValue = (this.editForm.set_tip - this.refValue).toFixed(2)
    //         this.flag = false
    //       }
    //     } else if (this.flag === false) {
    //       this.refValue = (this.editForm.set_tep * ((100 + this.editForm.set_ctr) / 100)).toFixed(2)
    //       this.taxValue = (this.editForm.set_tep * (this.editForm.set_ctr / 100)).toFixed(2)
    //       this.editForm.set_tip = Math.round(this.refValue)
    //       this.gapValue = (this.editForm.set_tip - this.refValue).toFixed(2)
    //     }
    //   },
    // },
    // 'editForm.set_tip': {
    //   handler() {
    //     if (this.gapValue !== 0) {
    //       this.gapValueOn = true
    //     } else {
    //       this.gapValueOn = false
    //     }
    //     this.gapValue = (this.editForm.set_tip - this.refValue).toFixed(2)
    //     if (this.flag === true) {
    //       if (Number(this.editForm.set_tip) !== this.selectedSet.set_tip) {
    //         this.gapValue = (this.editForm.set_tip - this.refValue).toFixed(2)
    //         this.flag = false
    //       }
    //     } else if (this.flag === false) {
    //       this.gapValue = (this.editForm.set_tip - this.refValue).toFixed(2)
    //     }
    //   },
    // },

    // 'editForm.details': {
    //   handler(newValue) {
    //     console.log(newValue)
    //     if (this.editForm.details.length === 0) {
    //       this.detailsErrors = true
    //     } else {
    //       this.detailsErrors = false
    //     }
    //   },
    // },
    files: {
      handler(newValue) {
        this.editForm.set_images = ''
        newValue.forEach((n, i) => {
          if (i === newValue.length - 1) {
            this.editForm.set_images += `"${n}"`
          } else {
            this.editForm.set_images += `"${n}",`
          }
        })

        // console.log('set_images', this.editForm.set_images)
      },
    },
  },
  created() {
    this.loadData()
  },
  destroyed() {
    this.clearSelectedSet()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('setStore', ['loadSet', 'editSet']),
    ...mapMutations('setStore', ['clearSelectedSet']),

    cancelClick() {
      if (!this.$isObjectEqual(this.editForm, this.selectedSet)) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },

    allowedDates(val) {
      return Date.parse(val) > Date.parse(this.editForm.set_ssd)
    },
    insertImgs(files) {
      if (files.length !== 0) {
        files.forEach(f => {
          this.files.push(f.url)
        })

        this.editForm.imgUrl_prefix = files[0].full_url.replace(files[0].url, '').replace(/\/$/g, '')
      }
    },
    openMenu(e, index) {
      this.rightClickIndex = index
      console.log('openMenu', this.rightClickIndex)

      const navMenu = document.getElementsByClassName('app-navigation-menu')[0].style.transform
      if (navMenu === 'translateX(0%)') {
        this.left = e.pageX - 268
      } else {
        this.left = e.pageX
      }
      this.top = e.pageY - 74

      this.visible = true
    },

    closeMenu() {
      this.visible = false
    },

    deleteFile() {
      this.files.splice(this.rightClickIndex, 1)
    },

    insertSetOverview(files) {
      let content = ''
      files.forEach(file => {
        content += `<img src="${file.full_url}" alt="${file.name}" /><br />`
      })
      this.editForm.set_overview += content

      console.log('insertSetOverview', this.editForm.set_overview)
    },

    insertSetDesc(files) {
      let content = ''
      files.forEach(file => {
        content += `<img src="${file.full_url}" alt="${file.name}" /><br />`
      })
      this.editForm.set_desc += content
      console.log('insertSetDesc', this.editForm.set_desc)
    },

    onOverviewEditor() {
      this.showOverviewEditor = !this.showOverviewEditor
    },
    onDescEditor() {
      this.showDescEditor = !this.showDescEditor
    },
    getColor(statusColor) {
      if (statusColor.type === 'main') {
        return 'primary'
      }
      if (statusColor.type === 'sup') {
        return 'success'
      }
      if (statusColor.type === 'soup') {
        return 'info'
      }
      if (statusColor.type === 'lunch') {
        return 'warning'
      }
      if (statusColor.type === 'dessert') {
        return 'error'
      }
      if (statusColor.type === 'other') {
        return 'Default'
      }

      return '#ffcda3'
    },
    getaddMainAllList(addMainAllList) {
      this.editForm.details = addMainAllList.map((mal, i) => {
        const item = {
        }
        item.item_code = mal.item_code
        item.item_name = mal.item_name
        item.item_up_value = mal.item_up_value
        item.item_qty = mal.item_qty ? mal.item_qty : 1
        item.totalPrice = mal.item_up_value * (mal.item_qty ? mal.item_qty : 1)
        item.item_disp_order = i + 1

        return item
      })
      this.allSetPrice = 0
      for (let i = 0; i < this.editForm.details.length; i += 1) {
        this.allSetPrice += this.editForm.details[i].totalPrice
      }

      // console.log('getaddMainAllList', this.editForm.details, this.allSetPrice)
    },
    increaseQty(item) {
      console.log('increaseQty', item.item_code)
      const index = this.editForm.details.findIndex(se => se.item_code === item.item_code)
      if (index >= 0) {
        this.editForm.details[index].item_qty += 1
      }
    },
    decreaseQty(item) {
      const index = this.editForm.details.findIndex(se => se.item_code === item.item_code)
      if (index >= 0 && item.item_qty > 1) {
        this.editForm.details[index].item_qty -= 1
      }
    },
    outFrList(item) {
      const index = this.editForm.details.findIndex(se => se.item_code === item.item_code)
      if (index >= 0) this.editForm.details.splice(index, 1)
    },
    onEnd() {
      this.editForm.details = this.editForm.details.map((ed, i) => {
        const item = {
          ...ed,
        }
        item.item_disp_order = i + 1

        return item
      })
      console.log('draggle end', this.editForm.details)
    },
    onNotfoodEdit() {
      this.unFoodEdit = !this.unFoodEdit
      if (this.unFoodEdit === true) {
        this.foodEdit = false
      }
    },
    jumpToListPage() {
      this.$router.push({
        path: '/itemset-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    loadData() {
      console.log('load,set_id:', this.$route.params.id)
      this.setOverlayStatus(true)
      this.loadSet(this.$route.params.id).then(() => {
        // this.editForm = {
        //   ...this.selectedSet,
        // }
        this.editForm = this.$deepCopy(this.selectedSet)
        console.log('#$$:', this.editForm)
        if (this.selectedSet.set_images) {
          this.selectedSet.set_images.split(',').forEach(i => {
            this.files.push(i.replace(/^"+|"$/g, ''))
          })
        }
      }).catch(error => {
        this.submitStatus = false
        console.log('error', error)
        this.apierror.status = 'error'
        this.apierror.code = error.response.data.code
        this.apierror.messages = error.response.data.message
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    submitTo(editForm) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true

        this.editSet(editForm)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!', this.editForm)
        window.scrollTo(0, 0)

        // if (this.editForm.details.length === 0) {
        //   this.detailsErrors = true
        // } else {
        //   this.detailsErrors = false
        // }
        this.apierror.messages = []
        this.apierror.status = 'error'
        this.apierror.messages.push('赤い部分の情報を修正してください')
        this.showOverviewEditor = false
        this.showDescEditor = false
      }
    },

    submit(editForm) {
      if (this.editForm.details.length !== 0) {
        if (this.editForm.show_style) {
          let sum = 0
          this.editForm.show_style.split('').forEach(s => {
            sum += parseInt(s, 10)
          })
          if (sum !== this.sumQuantity) {
            window.confirm('中身の総数は表示レイアウトの総数と等しくありません')
            document.getElementById('showStyle').scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
          } else {
            this.submitTo(editForm)
          }
        } else {
          window.confirm('表示レイアウトは入力してください')
          document.getElementById('showStyle').scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      } else if (!this.$isObjectEqual(editForm, this.selectedSet)) {
        this.submitTo(editForm)
      } else {
        this.jumpToListPage()
      }

      // console.log('editForm:', editForm)
      // if (window.confirm(`販売価格(税込み)\n入力値は ${Math.abs(this.editForm.set_tip)} 円, 計算値は ${Math.abs(this.refValue)} 円(差値は ${Math.abs(this.gapValue)} 円)\nよろしいですか?`)) {
      //   this.submitTo(editForm)
      // } else {
      //   this.$v.$reset()
      //   console.log(editForm)
      // }
    },
  },
}
</script>

<style>
.ql-editor{
  min-height: 120px;
}
</style>
